import './jquery';
import 'jquery-validation';
import {} from 'jquery-ujs';
import 'blueimp-file-upload/js/jquery.fileupload';
import 'blueimp-file-upload/js/jquery.fileupload-process';
import 'blueimp-file-upload/js/jquery.fileupload-validate';
import jQueryBridget from 'jquery-bridget';
import InfiniteScroll from 'infinite-scroll';

import imagesLoaded from 'imagesloaded';

import 'datatables.net';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import JSZip from 'jszip';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-rowgroup';
import 'datatables.net-rowreorder';

import './jquery-extensions/custom-helpers';
import './jquery-extensions/bounce';

import '../3p/jquery-ui.js'; // blueimp-file-upload requires custom jquery-ui build
import '../3p/trumbowyg/trumbowyg.js';
import '../3p/trumbowyg/trumbowyg.upload.js';
import '../3p/trumbowyg/trumbowyg.giphy.js';
import '../3p/trumbowyg/trumbowyg.tenor.js';
import '../3p/trumbowyg/trumbowyg.emoji.js';
import '../3p/slick';
import '../3p/lazy.min';
import '../3p/isotope'; // Isotope v1.x.x is not available in npm
import '../3p/isotope-centering';

window.imagesLoaded = imagesLoaded;
window.JSZip = JSZip;

jQueryBridget('infiniteScroll', InfiniteScroll, $);

window.$body = $(document.body);
window.body = document.body;
window.$document = $(document);
window.$window = window.$window || $(window);
window.$html = window.$html || $("html");
